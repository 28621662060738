<template>
  <div>
    <el-container>
      <el-aside width="300px">
        <el-tabs style="height: 700px" stretch v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="校区" name="first">校区</el-tab-pane>
          <el-tab-pane label="部门" name="second">部门</el-tab-pane>
          <el-tab-pane label="角色" name="third">角色</el-tab-pane>
        </el-tabs>
      </el-aside>
      <el-main>
        <div style="margin-bottom: 20px">
          <el-button type="danger" @click="addGroup">新 增</el-button>
          <el-button type="danger" @click="newProcess">搜 索</el-button>
        </div>

        <el-table
            :data="tableData"
            border class="table">
          <el-table-column
              prop="date"
              label="日期"
              width="180">
          </el-table-column>
          <el-table-column
              prop="name"
              label="姓名"
              width="180">
          </el-table-column>
          <el-table-column
              prop="address"
              label="地址">
          </el-table-column>
        </el-table>
        <el-pagination
            style="text-align: right;margin: 20px 10px"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="100"
            layout="total, prev, pager, next, jumper"
            :total="400">
        </el-pagination>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import '/src/assets/styles.css';
export default {
  name: "User",
  data() {
    return {
      activeName: 'second',
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1517 弄'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1519 弄'
      }, {
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1516 弄'
      }, {
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1516 弄'
      }, {
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1516 弄'
      }, {
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1516 弄'
      }, {
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1516 弄'
      }, {
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1516 弄'
      }, {
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1516 弄'
      }]
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    }
  }
}
</script>

<style scoped>
/deep/ .el-tabs__active-bar {
  background-color: #ff4d4f;
}
/**悬浮 */
::v-deep .el-tabs__item:hover {
  color: #ff4d4f;
  cursor: pointer;
  opacity: 1;
}
.table{
  width: 100%;
  border-radius: 10px;
  padding: 6px 10px 10px 10px;
  height: 550px;
}
.el-aside {
  text-align: center;
  padding: 10px 20px;
  margin: 8px 20px 0 0;
  border-radius: 10px;
  box-shadow: 0 0px 20px 0 rgba(94, 93, 93, 0.1)
}
</style>